import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, map } from "rxjs";
import { IResponseDTO } from "../interfaces/response.interface";

@Injectable({
  providedIn: "root",
})
export class ViolationService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {}

  public getViolations(
    property: string = "",
    limit: number = 10,
    page: number = 1,
    filter: any = {}
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      property,
      limit,
      page,
      filter,
    };

    return this.http
      .post<IResponseDTO<any[]>>(
        `${environment.apiUrl}/violations/search`,
        body,
        {
          withCredentials: true,
        }
      )
      .pipe(
        map(res => {
          res.data = res.data.map((v: any) => {
            if (v.letters.length === 0) {
              return v;
            }
            v.address = v.unit.address;
            v.mostRecentActivity = (v.letters ?? []).at(-1).date;
            return v;
          });

          return res;
        })
      );
  }

  public getResidentViolations(
    limit: number = 10,
    page: number = 1
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      limit,
      page,
    };

    return this.http
      .post<IResponseDTO<any[]>>(
        `${environment.apiUrl}/resident/violations/search`,
        body,
        {
          withCredentials: true,
        }
      )
      .pipe(
        map(res => {
          res.data = res.data.map((v: any) => {
            if (v.letters.length === 0) {
              return v;
            }
            v.address = v.unit.address;
            v.mostRecentActivity = (v.letters ?? []).at(-1).date;
            return v;
          });

          return res;
        })
      );
  }
  public getViolation = (id: string): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/violations/${id}`, {
      withCredentials: true,
    });
  };
  public getCategories = (): Observable<any> => {
    const url = `${this.apiUrl}/categories/violations`;
    return this.http.get<any>(url, { withCredentials: true });
  };
  public getAssociations = (): Observable<any> => {
    const url = `${this.apiUrl}/associations`;
    return this.http.get<any[]>(url, { withCredentials: true });
  };
  public getUnitsByAssociationId = (id: string): Observable<any> => {
    const url = `${this.apiUrl}/associations/${id}/units`;
    return this.http.get<any>(url, { withCredentials: true });
  };
  public getCategoriesById = (id: string): Observable<any> => {
    const url = `${this.apiUrl}/categories/violations/${id}`;
    return this.http.get<any>(url, { withCredentials: true });
  };
  public previewViolationLetter(body: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(
      `${this.apiUrl}/violations/preview`,
      body,
      {
        responseType: "arraybuffer" as "json",
        withCredentials: true,
      }
    );
  }
  public createViolation(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/violations`, body);
  }
  public getTemplates(property: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/templates/violation-letter`, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  public getParagraphs(property: string = ""): Observable<any> {
    return this.http.get(`${this.apiUrl}/subcategories/violations`, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  getViolationSubcategories(property: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/subcategories/violations`, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }
  public updateViolation(id: string, body: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/violations/` + id, body);
  }
}
